import * as React from 'react';
import * as Bootstrap from 'reactstrap';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as ActHomeStore from '../store/ActHomeStore';
import './ActHome.css';
import '../utilities/Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faFilter, faSortAlphaUp, faSortAlphaDown, faSortNumericDown, faSortNumericUp, IconDefinition, faSortNumericUpAlt, faSortAlphaUpAlt } from '@fortawesome/free-solid-svg-icons'
import { FilterMenu, ActiveFilter } from '../store/Models';
import CsvGenerator from '../utilities/CsvGenerator';
import { AppConfiguration } from '../Config';

// ===============================================================================

// At runtime, Redux will merge together...
type ActHomeProps =
    ActHomeStore.ActHomeState & // ... state we've requested from the Redux store
    AppConfiguration &
    typeof ActHomeStore.actionCreators & // ... plus action creators we've requested
    RouteComponentProps;

// ===============================================================================

class ActHome extends React.PureComponent<ActHomeProps> {
    // ===============================================================================

    // This method is called when the component is first added to the document
    public componentDidMount() {
        window.addEventListener("resize", this.window_resize);
        window.addEventListener("scroll", this.window_scroll);

        this.props.loadProductThumbnailsIfNeeded();
    }

    // ===============================================================================

    // This method is called when the route parameters change
    public componentDidUpdate() {
    }

    // ===============================================================================

    public componentWillUnmount() {
        window.removeEventListener("resize", this.window_resize);
        window.removeEventListener("scroll", this.window_scroll);
    }

    // ===============================================================================

    public render() {
        return (
            <div>
                {this.renderHeroSection()}
                {this.props.isLoading && <div className="container loading-message">Loading...</div>}
                {!this.props.isLoading && this.renderFiltersAndThumbnails()}
            </div>
        );
    }

    // ===============================================================================

    private renderHeroSection() {
        return (
            <div id="hero">
                <div className="image-container">
                    <img src="/img/act-hero.jpg" />
                </div>
                <div className="action-buttons-container">
                    <a href="https://act.mygreenlab.org/act-standard-form.html" className="btn btn-mgl-cta">
                        Download the ACT Standard
                    </a>
                    <a href="https://act.mygreenlab.org/manufacturers.html" className="btn btn-mgl-cta">
                        Label Your Products
                    </a>
                    <a href="https://act.mygreenlab.org/" className="btn btn-mgl-cta">
                        Learn More About ACT
                    </a>
                </div>
            </div>
        );
    }

    // ===============================================================================

    private renderFilters() {
        return (
            <div id="filters-container">
                <Bootstrap.Row>
                    <Bootstrap.Col md="6">
                        <div className="search-bar-container">
                            <Bootstrap.InputGroup>
                                <Bootstrap.Input id="search-input" onKeyDown={this.searchInput_keyDown} />
                                <Bootstrap.InputGroupAddon addonType="append">
                                    <Bootstrap.Button className="btn-mgl-primary" onClick={this.searchButton_click}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Bootstrap.Button>
                                </Bootstrap.InputGroupAddon>
                            </Bootstrap.InputGroup>
                        </div>
                    </Bootstrap.Col>
                </Bootstrap.Row>
                <Bootstrap.Row>
                    <Bootstrap.Col>
                        <div className="filter-menus-container">
                            {this.props.filterMenus.map(filterMenu => {
                                let sortAscendingIconDefinition: IconDefinition;
                                let sortDescendingIconDefinition: IconDefinition;
                                let sortAscendingDescription: string;
                                let sortDescendingDescription: string;

                                if (filterMenu.filterId === "environmental-impact-factor") {
                                    sortAscendingIconDefinition = faSortNumericDown;
                                    sortDescendingIconDefinition = faSortNumericUpAlt;
                                    sortAscendingDescription = "Smallest to Largest";
                                    sortDescendingDescription = "Largest to Smallest";
                                }
                                else {
                                    sortAscendingIconDefinition = faSortAlphaDown;
                                    sortDescendingIconDefinition = faSortAlphaUpAlt;
                                    sortAscendingDescription = "A to Z";
                                    sortDescendingDescription = "Z to A";
                                }

                                let activeSortIconDefinition: IconDefinition | null = null;

                                if (filterMenu.filterId === this.props.sortContext.filterId) {
                                    if (this.props.sortContext.sortDirection === ActHomeStore.SortDirectionType.Ascending) {
                                        activeSortIconDefinition = sortAscendingIconDefinition;
                                    }
                                    else {
                                        activeSortIconDefinition = sortDescendingIconDefinition;
                                    }
                                }

                                return (
                                    <Bootstrap.UncontrolledButtonDropdown key={filterMenu.filterId} className="filter-menu">
                                        <Bootstrap.DropdownToggle caret>
                                            <FontAwesomeIcon icon={faFilter} />{(activeSortIconDefinition !== null) && <FontAwesomeIcon icon={activeSortIconDefinition} /> } {filterMenu.title}
                                        </Bootstrap.DropdownToggle>
                                        <Bootstrap.DropdownMenu>
                                            <Bootstrap.DropdownItem onClick={e => this.filterMenuSortItem_click(e, filterMenu, ActHomeStore.SortDirectionType.Ascending)}><FontAwesomeIcon icon={sortAscendingIconDefinition} /> {sortAscendingDescription}</Bootstrap.DropdownItem>
                                            <Bootstrap.DropdownItem onClick={e => this.filterMenuSortItem_click(e, filterMenu, ActHomeStore.SortDirectionType.Descending)}><FontAwesomeIcon icon={sortDescendingIconDefinition} /> {sortDescendingDescription}</Bootstrap.DropdownItem>
                                            <Bootstrap.DropdownItem divider />
                                            {filterMenu.values.map((value, index) => (
                                                <Bootstrap.DropdownItem key={index} onClick={e => this.filterMenuItem_click(e, filterMenu, index)}>{value}</Bootstrap.DropdownItem>
                                            ))
                                            }
                                        </Bootstrap.DropdownMenu>
                                    </Bootstrap.UncontrolledButtonDropdown>
                                )
                            })
                            }
                            <Bootstrap.Button onClick={this.exportResults_click}>Export Results</Bootstrap.Button>
                        </div>
                    </Bootstrap.Col>
                </Bootstrap.Row>
                <Bootstrap.Row>
                    <Bootstrap.Col>
                        <div className="active-filters-container">
                            {this.props.activeFilters.map(activeFilter => {
                                let filterValueDescription = activeFilter.value;

                                if (activeFilter.filterId === "search") {
                                    filterValueDescription = `"${activeFilter.value}"`;
                                }
                                else if (activeFilter.filterId === "environmental-impact-factor") {
                                    filterValueDescription = `Environmental Impact Factor ${activeFilter.value}`;
                                }

                                return (
                                    <div key={`${activeFilter.filterId}|${activeFilter.value}`} className="active-filter">
                                        <span>{filterValueDescription}</span>
                                        <span><button type="button" onClick={e => this.removeActiveFilter_click(e, activeFilter)}>&times;</button></span>
                                    </div>
                                );
                            })
                            }
                        </div>
                    </Bootstrap.Col>
                </Bootstrap.Row>
            </div>
        );
    }

    // ===============================================================================

    private renderThumbnails() {
        const i_0 = Math.max(0, this.props.visibleProductsStart);
        const i_f = Math.min(this.props.filteredProductThumbnails.length, this.props.visibleProductsEnd + 1);

        const items: JSX.Element[] = [];

        for (let index = i_0; index < i_f; ++index) {
            const thumbnail = this.props.filteredProductThumbnails[index];

            items.push((
                <Link key={thumbnail.productId} to={`/products/${thumbnail.productId}`} className="card">
                    <div className="header">
                        <div className="product-name">{thumbnail.productName}</div>
                    </div>
                    <div className="product-info">
                        <div>{thumbnail.productCategory}</div>
                        <div>{thumbnail.productFamily}</div>
                        <div>Environmental Impact Factor: {thumbnail.environmentalImpactFactor}</div>
                        <div>{thumbnail.labelTypes.join(", ")}</div>
                    </div>
                    <div className="logo-container">
                        {thumbnail.manufacturerLogoFileId !== null &&
                            <img src={`${this.props.baseToxnotWebappEndpointUrl}/Api/ActLabelImage?fileId=${encodeURIComponent(thumbnail.manufacturerLogoFileId)}`} />
                        }
                    </div>
                </Link>
                )
            );
        }

        return (
            <div id="thumbnail-flex-box-container">
                <div style={{ height: this.props.productsSpacingTop.toString() + "px" }}></div>
                <div id="thumbnail-flex-box">
                    {(this.props.filteredProductThumbnails.length === 0) &&
                        <div className="no-results-message-container">No results found.</div>}
                    {items}
                </div>
                <div style={{ height: this.props.productsSpacingBottom.toString() + "px" }}></div>
            </div>
        );
    }

    // ===============================================================================

    private renderFiltersAndThumbnails() {
        return (
            <React.Fragment>
                {this.renderFilters()}
                {this.renderThumbnails()}
            </React.Fragment>
            );
    }

    // ===============================================================================

    private addSearchFilter() {
        const searchInput = document.getElementById("search-input") as HTMLInputElement;

        const query = searchInput.value.trim();

        if (query.length > 0) {
            this.props.activateFilter("search", query);

            searchInput.value = "";
        }
    }

    // ===============================================================================

    //
    // UI Events
    //

    // ===============================================================================

    private window_resize = (e: any) => {
        this.props.updateVisibleProducts();
    }

    // ===============================================================================

    private window_scroll = (e: any) => {
        this.props.updateVisibleProducts();
    }

    // ===============================================================================

    private searchInput_keyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            this.addSearchFilter();
        }
    }

    // ===============================================================================

    private searchButton_click = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.addSearchFilter();
    }

    // ===============================================================================

    private filterMenuSortItem_click = (e: React.MouseEvent<HTMLElement, MouseEvent>, filterMenu: FilterMenu, sortType: ActHomeStore.SortDirectionType) => {
        this.props.setSort(filterMenu.filterId, sortType);
    }

    // ===============================================================================

    private filterMenuItem_click = (e: React.MouseEvent<HTMLElement, MouseEvent>, filterMenu: FilterMenu, menuItemIndex: number) => {
        this.props.activateFilter(filterMenu.filterId, filterMenu.values[menuItemIndex]);
    }

    // ===============================================================================

    private removeActiveFilter_click = (e: React.MouseEvent<HTMLElement, MouseEvent>, activeFilter: ActiveFilter) => {
        this.props.deactivateFilter(activeFilter.filterId, activeFilter.value);
    }

    // ===============================================================================

    private exportResults_click = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        //
        // Make an array of rows to export into a CSV
        //

        const rows = [
            [
                "Product Name",
                "Manufacturer",
                "Product SKU",
                "Manufacturer Product ID",
                "Product Category",
                "Product Family",
                "Environmental Impact Factor",
            ]
        ];

        for (let thumbnail of this.props.filteredProductThumbnails) {
            rows.push([
                thumbnail.productName,
                thumbnail.manufacturerName,
                thumbnail.productSku,
                thumbnail.productManufacturerReferenceId,
                thumbnail.productCategory,
                thumbnail.productFamily,
                thumbnail.environmentalImpactFactor
            ]);
        }

        //
        // Turn the data into CSV file content
        //

        const csvGenerator = new CsvGenerator();

        const csvContent = csvGenerator.generateCsvContent(rows);

        //
        // Cause the browser to download the data
        //

        const exportFilename = "results.csv";

        const anchorElement = document.createElement("a");

        const mimeType = "text/csv; encoding:utf-8";

        if ((navigator as any).msSaveBlob) {
            // IE support
            (navigator as any).msSaveBlob(new Blob([csvContent], { type: mimeType }), exportFilename);
        }
        else if (URL && ('download' in anchorElement)) {
            // modern browser solution
            anchorElement.href = URL.createObjectURL(new Blob([csvContent], { type: mimeType }));
            anchorElement.setAttribute("download", exportFilename);
            document.body.appendChild(anchorElement);
            anchorElement.click();
            document.body.removeChild(anchorElement);
        }
        else {
            // fallback download
            window.location.href = "data:application/octet-stream," + encodeURIComponent(csvContent);
        }
    }
}

// ===============================================================================

export default connect(
    (state: ApplicationState) => ({ ...state.actHomeState, ...state.configuration }),
    ActHomeStore.actionCreators // Selects which action creators are merged into the component's props
)(ActHome as any);
