//
// Utility extensions and functions
//

// ===============================================================================

declare global {
    interface String {
        trimWordsToMaxNumChars(this: string, maxNumChars: number): string;
        trimStartString(this: string, startString: string): string;
        trimEndString(this: string, endString: string): string;
        nullOrEmptyCoalesce(this: string, coalesceValue: string): string;
        csvEncode(this: string): string;
        coalesceToMaxSizeAndIndicateTruncationWithEllipsis(this: string, maxSize: number): string;
    }

    interface Array<T> {
        distinct(this: Array<T>): Array<T>;
        firstOrDefault(this: Array<T>, predicate: (obj: T) => boolean): T;
        selectMany<TSelector>(this: Array<T>, selector: (obj: T) => Array<TSelector>): Array<TSelector>;
    }
}

// ===============================================================================

//
// String extensions
//

String.prototype.trimWordsToMaxNumChars = function (this: string, maxNumChars: number): string {
    if (this.length <= maxNumChars) {
        return this;
    }

    let chop = this.substring(0, maxNumChars);

    if (this[maxNumChars] === " ") {
        return chop + "...";
    }

    let i = chop.length - 1;

    while ((i > 0) && (chop[i] !== " ")) {
        --i;
    }

    chop = chop.substring(0, i + 1) + "...";

    return chop;
};

// ===============================================================================

String.prototype.trimStartString = function (this: string, startString: string): string {
    if (this.startsWith(startString)) {
        return this.substring(startString.length);
    }
    else {
        return this;
    }
};

// ===============================================================================

String.prototype.trimEndString = function (this: string, endString: string): string {
    if (this.endsWith(endString)) {
        return this.substring(0, this.length - endString.length);
    }
    else {
        return this;
    }
};

// ===============================================================================

String.prototype.nullOrEmptyCoalesce = function (this: string, coalesceValue: string): string {
    const s = this ?? "";

    return (s.length === 0) ? coalesceValue : this;
};

// ===============================================================================

String.prototype.csvEncode = function (this: string): string {
    return `"${this.replace('"', '""')}"`;
}

// ===============================================================================

String.prototype.coalesceToMaxSizeAndIndicateTruncationWithEllipsis = function (this: string, maxSize: number): string {
    maxSize = Math.max(maxSize, 3);

    if (this.length <= maxSize) {
        return this.valueOf();
    }

    return this.substring(0, Math.min(this.length, maxSize - 3)) + "...";
}

// ===============================================================================

//
// Array extensions
//

Array.prototype.distinct = function <T>(this: Array<T>): Array<T> {
    return Array.from(new Set<T>(this));
};

// ===============================================================================

Array.prototype.firstOrDefault = function <T>(this: Array<T>, predicate: (obj: T) => boolean): T | null {
    for (let i = 0; i < this.length; i++) {
        if (predicate(this[i])) {
            return this[i];
        }
    }

    return null;
}

// ===============================================================================

Array.prototype.selectMany = function <T, TSelector>(this: Array<T>, selector: (obj: T) => Array<TSelector>): Array<TSelector> {
    const ret: Array<TSelector> = [];

    for (let item of this) {
        const mapped = selector(item);

        for (let mappedItem of mapped) {
            ret.push(mappedItem);
        }
    }

    return ret;
}

// ===============================================================================

export { }
