import { Action, Reducer } from 'redux';
import * as ActHomeStore from './ActHomeStore';
import * as ProductDetailStore from './ProductDetailStore';
import ConfigManager from '../Config';
import { AppConfiguration } from '../Config';

// The top-level state object
export interface ApplicationState {
    configuration: AppConfiguration | undefined;
    actHomeState: ActHomeStore.ActHomeState | undefined;
    productDetailState: ProductDetailStore.ProductDetailState | undefined;
}

const configurationReducer: Reducer<AppConfiguration> = (state: AppConfiguration | undefined, incomingAction: Action): AppConfiguration => {
    if (state === undefined) {
        const toxnotEnvironment = (window as any).toxnotEnvironment as string;

        const configManager = new ConfigManager(toxnotEnvironment ?? "development");

        return configManager.configuration;
    }
    else {
        return state;
    }
};

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    configuration: configurationReducer,
    actHomeState: ActHomeStore.reducer,
    productDetailState: ProductDetailStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
