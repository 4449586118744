import * as React from 'react';
import * as Bootstrap from 'reactstrap';
import './CommonPageFooter.css';

export default () => (
    <footer>
        <Bootstrap.Container>
            <Bootstrap.Row id="footer-top-border">
                <Bootstrap.Col>
                    <div className="line"></div>
                </Bootstrap.Col>
            </Bootstrap.Row>
            <Bootstrap.Row id="footer-content-row">
                <Bootstrap.Col sm>
                    <h6>Sign Up for Updates</h6>
                    <div id="sign-up-form-container">
                        {/* <!-- Begin Mailchimp Signup Form --> */}
                        <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" />
                        <div id="mc_embed_signup" style={{ backgroundColor: "#fff", font: "14px Helvetica,Arial,sans-serif" }}>
                            <form action="https://mygreenlab.us13.list-manage.com/subscribe/post?u=8fbd23c10778e0676fb2a9b41&amp;id=0c90a32fd9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate={true} style={{ paddingTop: "0", paddingLeft: "0" }}>
                                <div id="mc_embed_signup_scroll">
                                    <h2></h2>
                                    <div className="mc-field-group">
                                        <label htmlFor="mce-FNAME">First Name</label>
                                        <input type="text" defaultValue="" name="FNAME" id="mce-FNAME" />
                                    </div>
                                    <div className="mc-field-group">
                                        <label htmlFor="mce-LNAME">Last Name</label>
                                        <input type="text" defaultValue="" name="LNAME" id="mce-LNAME" />
                                    </div>
                                    <div className="mc-field-group">
                                        <label htmlFor="mce-EMAIL">Email Address</label>
                                        <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" />
                                    </div>
                                    <div id="mce-responses" className="clear">
                                        <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                                        <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
                                    </div>
                                    {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                                    <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" name="b_8fbd23c10778e0676fb2a9b41_0c90a32fd9" defaultValue="" /></div>
                                    <div className="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
                                </div>
                            </form>
                        </div>
                        <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
                        <script type='text/javascript' dangerouslySetInnerHTML={{ __html: "(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[0]='EMAIL';ftypes[0]='email';}(jQuery));var $mcj = jQuery.noConflict(true);"}}></script>
                        {/* <!--End mc_embed_signup--> */}
                    </div>
                </Bootstrap.Col>
                <Bootstrap.Col sm>
                    <h6 className="text-center">About My Green Lab</h6>
                    <p className="text-center">
                        My Green Lab is a 501c3 non-profit organization.<br />
                        All gifts are tax deductible in accordance with IRS regulations.
                    </p>
                    <p id="social-media-links-container" className="text-center">
                        <a href="https://www.facebook.com/mygreenlab/" target="_blank"><img src="/img/facebook-icon.png" /></a>
                        <a href="https://twitter.com/my_green_lab" target="_blank"><img src="/img/twitter-icon.png" /></a>
                        <a href="https://www.linkedin.com/company/my-green-lab" target="_blank"><img src="/img/linked-in-icon.png" /></a>
                        <a href="mailto:info@mygreenlab.org" target="_blank"><img src="/img/email-icon.png" /></a>
                    </p>
                    <div className="toxnot-container">
                        <a href="https://exchange.3eco.com" target="_blank">Powered by <img src="/img/footer-toxnot.png" /></a>
                    </div>
                </Bootstrap.Col>
                <Bootstrap.Col sm>
                    <h6>Contact Us</h6>
                    <p>
                        <a href="https://mygreenlab.org">My Green Lab</a><br />
                        San Diego, CA 92109<br />
                        424.354.1494<br />
                        info@mygreenlab.org<br />
                    </p>
                    <p>
                        Copyright 2013-{new Date().getFullYear()} My Green Lab, all rights reserved.
                    </p>
                </Bootstrap.Col>
            </Bootstrap.Row>
        </Bootstrap.Container>
    </footer>
);
