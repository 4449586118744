export interface AppConfiguration {
    baseToxnotWebappEndpointUrl: string;
}

export default class ConfigManager {
    public configuration: AppConfiguration;

    private readonly _globalAppConfiguration: { [environment: string]: AppConfiguration } = {
        "prod": {
            baseToxnotWebappEndpointUrl: "https://exchange.3eco.com"
        },
        "production": {
            baseToxnotWebappEndpointUrl: "https://exchange.3eco.com"
        },
        "beta": {
            baseToxnotWebappEndpointUrl: "https://toxnotwebapplication-beta.azurewebsites.net"
        },
        "development": {
            baseToxnotWebappEndpointUrl: "https://localhost:44359"
        }
    };

    public constructor(environment: string) {
        this.configuration = this._globalAppConfiguration[environment] ?? this._globalAppConfiguration["development"];
    }
}
