import * as React from 'react';
import * as Bootstrap from 'reactstrap';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import { MyGreenLabProduct } from '../store/Models';
import * as ProductDetailStore from '../store/ProductDetailStore';
import './ProductDetail.css';
import { AppConfiguration } from '../Config';

// ===============================================================================

// At runtime, Redux will merge together...
type DeclareLabelDetailProps =
    ProductDetailStore.ProductDetailState & // ... state we've requested from the Redux store
    AppConfiguration &
    typeof ProductDetailStore.actionCreators & // ... plus action creators we've requested
    RouteComponentProps<{ productId: string }>;

// ===============================================================================

class ProductDetail extends React.PureComponent<DeclareLabelDetailProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.loadDataIfNeeded();
    }

    // ===============================================================================

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.loadDataIfNeeded();
    }

    // ===============================================================================

    public render() {
        return (
            <div>
                {this.props.product === null && <div className="container" style={{ paddingTop: "40px", paddingBottom: "40px" }}><div className="row"><div className="col-md-12">Loading...</div></div></div>}
                {this.props.product !== null && this.renderProduct(this.props.product)}
            </div>
        );
    }

    // ===============================================================================

    public renderProduct(product: MyGreenLabProduct) {
        const rationaleList = [
            {
                title: "Manufacturing Impact Reduction",
                content: product.baseLabelData.rationales.manufacturingImpactReduction
            },
            {
                title: "Renewable Energy Use",
                content: product.baseLabelData.rationales.renewableEnergyUse
            },
            {
                title: "Responsible Chemical Management",
                content: product.baseLabelData.rationales.responsibleChemicalManagement
            },
            {
                title: "Shipping Impact",
                content: product.baseLabelData.rationales.shippingImpact
            },
            {
                title: "Product Content",
                content: product.baseLabelData.rationales.productContent
            },
            {
                title: "Packaging Content",
                content: product.baseLabelData.rationales.packagingContent
            },
            {
                title: "Energy Consumption",
                content: product.baseLabelData.rationales.energyConsumption
            },
            {
                title: "Water Consumption",
                content: product.baseLabelData.rationales.waterConsumption
            },
            {
                title: "Lifetime Rating",
                content: product.baseLabelData.rationales.lifetimeRating
            },
            {
                title: "Packaging End-of-Life",
                content: product.baseLabelData.rationales.packagingEndOfLife
            },
            {
                title: "Product End-of-Life",
                content: product.baseLabelData.rationales.productEndOfLife
            },
        ];

        if ((product.baseLabelData.rationales.innovativePractices.length > 0) && (product.baseLabelData.rationales.innovativePractices.trim() !== "0")) {
            rationaleList.push({
                title: "Innovative Practices",
                content: product.baseLabelData.rationales.innovativePractices
            });
        }

        const labels = product.labels.filter(label => label.labelImageFileId !== null);

        return (
            <div>
                {this.props.history.length > 1 &&
                    <Bootstrap.Row>
                        <Bootstrap.Col>
                            <Bootstrap.Button id="back-button" className="btn-mgl-primary" onClick={(evt) => { this.props.history.goBack(); }}>Back</Bootstrap.Button>
                        </Bootstrap.Col>
                    </Bootstrap.Row>
                }
                <Bootstrap.Row>
                    <Bootstrap.Col>
                        <div id="product-container">
                            <div className="header">
                                <h1>{product.productName} {product.labels.map(l => l.labelType).join("/")}</h1>
                            </div>
                            <div className="label-container">
                                {labels.map(label => (
                                    <div key={label.labelImageFileId} className="label" style={{ width: `${100.0 / labels.length}%` }}>
                                        <img src={`${this.props.baseToxnotWebappEndpointUrl}/Api/ActLabelImage?productId=${encodeURIComponent(product.productId)}&fileId=${encodeURIComponent(label.labelImageFileId)}`} />
                                        <p>
                                            <a href={`${this.props.baseToxnotWebappEndpointUrl}/Api/ActLabelImage?productId=${encodeURIComponent(product.productId)}&fileId=${encodeURIComponent(label.printQualityLabelImageFileId)}&attachment=True`}>Download Label</a>
                                        </p>
                                    </div>
                                    ))
                                }
                            </div>
                            <div className="rationales-container">
                                <h1>Additional Information</h1>
                                {rationaleList.map((rationale, index) => (
                                    <div key={index} className="rationale">
                                        <div className="title">{rationale.title}</div>
                                        <div className="content">
                                            {rationale.content.split('\n').map((s, index2) => (
                                                <p key={index2}>
                                                    {s.trim()}
                                                </p>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    ))
                                }
                            </div>
                            {(product.productWebsiteHyperlinkUrl.length > 0) &&
                                <div className="website-hyperlink-container">
                                    <a href={product.productWebsiteHyperlinkUrl} target="_blank">{product.productWebsiteHyperlinkText.nullOrEmptyCoalesce("Product Website")}</a>
                                </div>
                            }
                        </div>
                    </Bootstrap.Col>
                </Bootstrap.Row>
            </div>
        );
    }

    // ===============================================================================

    private loadDataIfNeeded() {
        this.props.loadProduct(this.props.match.params.productId);
    }
}

// ===============================================================================

export default connect(
    (state: ApplicationState) => ({ ...state.productDetailState, ...state.configuration }), // Selects which state properties are merged into the component's props
    ProductDetailStore.actionCreators // Selects which action creators are merged into the component's props
)(ProductDetail as any);
