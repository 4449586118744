//
// The store for the state of the label detail page, which
// is a view of a single declare label product with full
// info about the product.
//

import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { ApiDataResponse } from './ApiResponse';
import { MyGreenLabProduct } from './Models';
import '../utilities/Utilities';

// ===============================================================================

//
// State
//
// This defines the data maintained in the Redux store.
//

export interface ProductDetailState {
    isLoading: boolean;
    product: MyGreenLabProduct | null;
};

// ===============================================================================

//
// Actions
//
// These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side - effects; they just describe something that is going to happen.
// 

interface RequestProductDetailAction {
    type: "REQUEST_PRODUCT_DETAIL";
    productIdToFetch: string;
}

interface ReceiveProductDetailAction {
    type: "RECEIVE_PRODUCT_DETAIL";
    product: MyGreenLabProduct;
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestProductDetailAction |
    ReceiveProductDetailAction;

// ===============================================================================

//
// Action Creators
//
// These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
//

export const actionCreators = {
    loadProduct: (productIdToFetch: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState &&
            appState.productDetailState &&
            !appState.productDetailState.isLoading &&
            ((appState.productDetailState.product === null) ||
                (appState.productDetailState.product.productId !== productIdToFetch))) {
            fetch(`api/products/${productIdToFetch}`)
                .then(response => response.json() as Promise<ApiDataResponse<MyGreenLabProduct>>)
                .then(apiDataResponse => {
                    dispatch({ type: "RECEIVE_PRODUCT_DETAIL", product: apiDataResponse.data });
                });

            dispatch({ type: "REQUEST_PRODUCT_DETAIL", productIdToFetch: productIdToFetch });
        }
    }
};

// ===============================================================================

//
// Reducer
//
// For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
//

const unloadedState: ProductDetailState = {
    isLoading: false,
    product: null
};

export const reducer: Reducer<ProductDetailState> = (state: ProductDetailState | undefined, incomingAction: Action): ProductDetailState => {
    if (state === undefined) {
        return unloadedState;
    }

    switch (incomingAction.type) {
        case "REQUEST_PRODUCT_DETAIL":
            return {
                isLoading: true,
                product: null
            };
        case "RECEIVE_PRODUCT_DETAIL":
            return {
                isLoading: false,
                product: (incomingAction as ReceiveProductDetailAction).product
            };
    }

    return state;
};
