import '../utilities/Utilities';

// ===============================================================================

export default class CsvGenerator {
    public constructor() {

    }

    public generateCsvContent(rows: Array<Array<string>>) {
        return rows
            .map(row => row.map(col => col.csvEncode()).join(","))
            .join("\n") + "\n";
    }
}
