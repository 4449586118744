import * as React from 'react';
import * as Bootstrap from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import './CommonPageHeader.css';

export default () => {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Bootstrap.Container id="header-container">
            <Bootstrap.Navbar id="main-navbar" color="white" light expand="md" style={{ paddingLeft: "0", paddingRight: "0" }}>
                <div className="d-flex flex-column align-items-start w-100">
                    <div id="logo-nav" className="d-flex flex-row w-100">
                        <Bootstrap.NavbarBrand href="/"><img src="/img/header-act-logo.png" alt="Act" /></Bootstrap.NavbarBrand>
                        <Bootstrap.NavbarToggler onClick={toggle} />
                    </div>
                    <Bootstrap.Collapse id="navbar-container" isOpen={isOpen} navbar>
                        <Bootstrap.Nav navbar>
                            <Bootstrap.NavItem>
                                <Bootstrap.NavLink href="https://act.mygreenlab.org/">About</Bootstrap.NavLink>
                            </Bootstrap.NavItem>
                            <Bootstrap.NavItem>
                                <Bootstrap.NavLink tag={Link} to="/" active>Act Database</Bootstrap.NavLink>
                            </Bootstrap.NavItem>
                            <Bootstrap.NavItem>
                                <Bootstrap.NavLink href="https://act.mygreenlab.org/scientists.html">Scientists</Bootstrap.NavLink>
                            </Bootstrap.NavItem>
                            <Bootstrap.NavItem>
                                <Bootstrap.NavLink href="https://act.mygreenlab.org/procurement.html">Procurement</Bootstrap.NavLink>
                            </Bootstrap.NavItem>
                            <Bootstrap.NavItem>
                                <Bootstrap.NavLink href="https://act.mygreenlab.org/manufacturers.html">Manufacturers</Bootstrap.NavLink>
                            </Bootstrap.NavItem>
                            <Bootstrap.NavItem>
                                <Bootstrap.NavLink href="https://www.mygreenlab.org/">MGL Home</Bootstrap.NavLink>
                            </Bootstrap.NavItem>
                        </Bootstrap.Nav>
                    </Bootstrap.Collapse>
                </div>
            </Bootstrap.Navbar>
        </Bootstrap.Container>
    );
}
