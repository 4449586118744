import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import ActHome from './components/ActHome';
import ProductDetail from './components/ProductDetail';
import StaticPageReloader from './components/StaticPageReloader';

export default () => (
    <Layout>
        {/* Pick the first matching Route... */}
        <Switch>
            <Route path='/' exact component={ActHome} />
            <Route path='/products/:productId' component={ProductDetail} />
            {/* Use This catch-all route refreshes the page to re-load the URL from the webserver*/}
            {/* <Route component={StaticPageReloader} /> */}
        </Switch>
    </Layout>
);
