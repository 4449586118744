//
// The store for the state of the home page, which is a view
// of all published ACT labels, along with search and filters.
//

import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

import { ActiveFilter, FilterMenu, MyGreenLabProductThumbnail } from './Models';
import { ApiDataResponse } from './ApiResponse';

// ===============================================================================

//
// State
//
// This defines the data maintained in the Redux store.
//

export enum SortDirectionType {
    Ascending,
    Descending
}

export interface SortContext {
    filterId: string;
    sortDirection: SortDirectionType;
}

export interface ActHomeState {
    isLoading: boolean;
    sortContext: SortContext;
    activeFilters: ActiveFilter[];
    filterMenus: FilterMenu[];
    allProductThumbnails: MyGreenLabProductThumbnail[];
    filteredProductThumbnails: MyGreenLabProductThumbnail[];
    visibleProductsStart: number; // index into filteredProductThumbnails
    visibleProductsEnd: number; // index into filteredProductThumbnails
    productsSpacingTop: number; // in pixels
    productsSpacingBottom: number; // in pixels
};

// ===============================================================================

//
// Actions
//
// These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side - effects; they just describe something that is going to happen.
// 

interface RequestProductThumbnailsAction {
    type: "REQUEST_PRODUCT_THUMBNAILS";
}

interface ReceiveProductThumbnailsAction {
    type: "RECEIVE_PRODUCT_THUMBNAILS";
    productThumbnails: MyGreenLabProductThumbnail[];
}

interface SetActiveFiltersAction {
    type: "SET_ACTIVE_FILTERS";
    activeFilters: ActiveFilter[];
}

interface SetSortAction {
    type: "SET_SORT";
    filterId: string;
    sortDirection: SortDirectionType;
}

interface ActivateFilterAction {
    type: "ACTIVATE_FILTER";
    filterId: string;
    filterValue: string;
}

interface DeactivateFilterAction {
    type: "DEACTIVATE_FILTER";
    filterId: string;
    filterValue: string;
}

interface UpdateVisibleProductsAction {
    type: "UPDATE_VISIBLE_PRODUCTS";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestProductThumbnailsAction |
    ReceiveProductThumbnailsAction |
    SetActiveFiltersAction |
    SetSortAction |
    ActivateFilterAction |
    DeactivateFilterAction |
    UpdateVisibleProductsAction;

// ===============================================================================

//
// Action Creators
//
// These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
//

export const actionCreators = {
    loadProductThumbnailsIfNeeded: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();

        if (appState && appState.actHomeState) {
            if (appState.actHomeState.allProductThumbnails.length === 0) {
                fetch("api/products")
                    .then(response => response.json() as Promise<ApiDataResponse<MyGreenLabProductThumbnail[]>>)
                    .then(apiDataResponse => {
                        dispatch({ type: "RECEIVE_PRODUCT_THUMBNAILS", productThumbnails: apiDataResponse.data });
                    });

                dispatch({ type: "REQUEST_PRODUCT_THUMBNAILS" });
            }
            else {
                dispatch({ type: "UPDATE_VISIBLE_PRODUCTS" });
            }
        }
    },
    setActiveFilters: (activeFilters: ActiveFilter[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.actHomeState) {
            dispatch({ type: "SET_ACTIVE_FILTERS", activeFilters: activeFilters });
        }
    },
    setSort: (filterId: string, sortDirection: SortDirectionType): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.actHomeState) {
            dispatch({ type: "SET_SORT", filterId: filterId, sortDirection: sortDirection });
        }
    },
    activateFilter: (filterId: string, value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.actHomeState) {
            dispatch({ type: "ACTIVATE_FILTER", filterId: filterId, filterValue: value });
        }
    },
    deactivateFilter: (filterId: string, value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.actHomeState) {
            dispatch({ type: "DEACTIVATE_FILTER", filterId: filterId, filterValue: value });
        }
    },
    updateVisibleProducts: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.actHomeState) {
            dispatch({ type: "UPDATE_VISIBLE_PRODUCTS" });
        }
    }
};

// ===============================================================================

//
// Implementation functions
//

function getFiltersForThumbnails(productThumbnails: MyGreenLabProductThumbnail[]): FilterMenu[] {
    const filterMenus: FilterMenu[] = [
        {
            filterId: "product-category",
            title: "Category",
            values: productThumbnails
                .map(t => t.productCategory.trim())
                .filter(s => s.length > 0)
                .distinct()
                .sort()
        },
        {
            filterId: "manufacturer",
            title: "Manufacturer",
            values: productThumbnails
                .map(t => t.manufacturerName.trim())
                .filter(s => s.length > 0)
                .distinct()
                .sort()
        },
        {
            filterId: "environmental-impact-factor",
            title: "Environmental Impact Factor",
            values: ["< 50", ">= 50"]
        },
    ];

    return filterMenus;
}

// ===============================================================================

function sortThumbnails(thumbnails: MyGreenLabProductThumbnail[], sortContext: SortContext) {
    const getStringValue = (t: MyGreenLabProductThumbnail, filterId: string) => {
        switch (filterId) {
            case "product-name":
                return t.productName;
            case "product-category":
                return t.productCategory;
            case "manufacturer":
                return t.manufacturerName;
        }

        return "";
    }

    const getNumericValue = (t: MyGreenLabProductThumbnail, filterId: string) => {
        if (filterId === "environmental-impact-factor") {
            const n = parseFloat(t.environmentalImpactFactor);

            return isNaN(n) ? 0 : n;
        }

        return 0;
    }

    thumbnails.sort((a, b) => {
        if (sortContext.filterId === "environmental-impact-factor") {
            const n_a = getNumericValue(a, sortContext.filterId);
            const n_b = getNumericValue(b, sortContext.filterId);

            if (sortContext.sortDirection === SortDirectionType.Ascending) {
                if (n_a < n_b) {
                    return -1;
                }
                else if (n_a > n_b) {
                    return 1;
                }
                else {
                    return 0;
                }
            }
            else {
                if (n_a < n_b) {
                    return 1;
                }
                else if (n_a > n_b) {
                    return -1;
                }
                else {
                    return 0;
                }
            }
        }
        else {
            const v_a = getStringValue(a, sortContext.filterId);
            const v_b = getStringValue(b, sortContext.filterId);

            if (sortContext.sortDirection === SortDirectionType.Ascending) {
                if (v_a < v_b) {
                    return -1;
                }
                else if (v_a > v_b) {
                    return 1;
                }
                else {
                    return 0;
                }
            }
            else {
                if (v_a < v_b) {
                    return 1;
                }
                else if (v_a > v_b) {
                    return -1;
                }
                else {
                    return 0;
                }
            }
        }
    });
}

// ===============================================================================

function getSearchableStringFromThumbnail(thumbnail: MyGreenLabProductThumbnail) {
    const ret = [
        thumbnail.productName,
        thumbnail.productSku,
        thumbnail.manufacturerName
    ];

    return ret.map(s => s.trim().toLowerCase());
}

// ===============================================================================

function applySearchFilter(
    thumbnail: MyGreenLabProductThumbnail,
    searchTerms: string[]
) {
    const searchableStringsForThumbnail = getSearchableStringFromThumbnail(thumbnail);

    const matchFound = searchableStringsForThumbnail.some(s => searchTerms.every(st => s.indexOf(st) !== -1));

    return matchFound;
}

// ===============================================================================

function applyEnvironmentalImpactFactorFilter(
    thumbnail: MyGreenLabProductThumbnail,
    filterValue: string
) {
    let environmentalImpactFactorNumber = parseFloat(thumbnail.environmentalImpactFactor);

    if (isNaN(environmentalImpactFactorNumber)) {
        environmentalImpactFactorNumber = 0.0;
    }

    switch (filterValue) {
        case "< 50":
            return environmentalImpactFactorNumber < 50;
        case ">= 50":
            return environmentalImpactFactorNumber >= 50;
    }

    return false;
}

// ===============================================================================

function applyActiveFilters(
    allProductThumbnails: MyGreenLabProductThumbnail[],
    activeFilters: ActiveFilter[]
): MyGreenLabProductThumbnail[] {
    if (activeFilters.length === 0) {
        return allProductThumbnails;
    }

    const searchQueryToListOfSearchTermsMap: { [query: string]: string[] } = {};

    for (let searchFilter of activeFilters.filter(f => f.filterId === "search")) {
        const query = searchFilter.value;
        const searchTerms = query.split(' ').map(s => s.trim().toLowerCase()).filter(s => s.length > 0);;

        searchQueryToListOfSearchTermsMap[query] = searchTerms;
    }

    return allProductThumbnails
        .filter(thumbnail => {
            const passedMap =
                activeFilters.reduce(
                    (accumulator, currentFilter) => {
                        accumulator[currentFilter.filterId] = false;

                        return accumulator;
                    },
                    {} as { [filterId: string]: boolean });

            for (let activeFilter of activeFilters) {
                let valueToCompare: string | null = null;
                let passedFilter = false;

                switch (activeFilter.filterId) {
                    case "search":
                        passedFilter = applySearchFilter(thumbnail, searchQueryToListOfSearchTermsMap[activeFilter.value]);
                        break;
                    case "manufacturer":
                        valueToCompare = thumbnail.manufacturerName.trim().toLowerCase();
                        break;
                    case "product-category":
                        valueToCompare = thumbnail.productCategory.trim().toLowerCase();
                        break;
                    case "environmental-impact-factor":
                        passedFilter = applyEnvironmentalImpactFactorFilter(thumbnail, activeFilter.value);
                        break;
                }

                if ((valueToCompare !== null) && (valueToCompare === activeFilter.value.trim().toLowerCase())) {
                    passedFilter = true;
                }

                if (passedFilter) {
                    passedMap[activeFilter.filterId] = true;
                }
            }

            return Object.keys(passedMap).every(filterId => passedMap[filterId]);
        });
}

// ===============================================================================

function getCurrentlyVisibleProducts(totalNumProducts: number): [number, number, number, number] {
    //
    // H_Top === height of all content above the card box
    // H_R === height of row in the card box
    //

    const headerElement = document.getElementById("header-container");
    const heroElement = document.getElementById("hero");
    const filtersElement = document.getElementById("filters-container");

    const width = window.outerWidth;

    const headerHeight = headerElement?.offsetHeight ?? 142;
    const heroHeight = heroElement?.offsetHeight ?? 200;
    const filtersHeight = filtersElement?.offsetHeight ?? 0;
    const productHeight = 290;
    const productMarginButtom = 20;

    const productsPerRow =
        (width >= 1200)
            ? 4
            : (width >= 991)
                ? 3
                : (width >= 768)
                    ? 2
                    : 1;

    const maxRowIndex = Math.floor(totalNumProducts / productsPerRow);

    const y0 = window.scrollY;
    const H_W = window.outerHeight;
    const yf = y0 + H_W;

    const H_Top = headerHeight + heroHeight + filtersHeight;
    const H_R = productHeight + productMarginButtom;

    const getRowIndexForYPosition = (y: number) => Math.floor((y - H_Top) / H_R);
    const getProductIndexForRowIndex = (r: number) => r * productsPerRow;

    const bufferRows = 25;

    const startRowIndex = Math.max(0, getRowIndexForYPosition(y0) - bufferRows);
    const endRowIndex = Math.min(getRowIndexForYPosition(yf) + bufferRows, maxRowIndex);

    const startProductIndex = Math.max(0, getProductIndexForRowIndex(startRowIndex));
    const endProductIndex = Math.min(totalNumProducts, getProductIndexForRowIndex(endRowIndex) + productsPerRow - 1)

    const spacingTop = startRowIndex * H_R;
    const spacingBottom = (maxRowIndex - endRowIndex) * H_R;

    return [
        startProductIndex,
        endProductIndex,
        spacingTop,
        spacingBottom
    ];
}

// ===============================================================================

//
// Reducer
//
// For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
//

const unloadedState: ActHomeState = {
    isLoading: true,
    sortContext: {
        filterId: "product-name",
        sortDirection: SortDirectionType.Ascending
    },
    activeFilters: [],
    filterMenus: [],
    allProductThumbnails: [],
    filteredProductThumbnails: [],
    visibleProductsStart: -1,
    visibleProductsEnd: -1,
    productsSpacingTop: 0,
    productsSpacingBottom: 0
};

export const reducer: Reducer<ActHomeState> = (state: ActHomeState | undefined, incomingAction: Action): ActHomeState => {
    if (state === undefined) {
        return unloadedState;
    }

    let visibleProductsStart: number;
    let visibleProductsEnd: number;
    let productsSpacingTop: number;
    let productsSpacingBottom: number;
    let filteredProductThumbnails: MyGreenLabProductThumbnail[];
    let newActiveFilters: ActiveFilter[];

    switch (incomingAction.type) {
        case "REQUEST_PRODUCT_THUMBNAILS":
            return {
                isLoading: true,
                sortContext: state.sortContext,
                activeFilters: state.activeFilters,
                filterMenus: state.filterMenus,
                allProductThumbnails: state.allProductThumbnails,
                filteredProductThumbnails: state.filteredProductThumbnails,
                visibleProductsStart: state.visibleProductsStart,
                visibleProductsEnd: state.visibleProductsEnd,
                productsSpacingTop: state.productsSpacingTop,
                productsSpacingBottom: state.productsSpacingBottom
            };
        case "RECEIVE_PRODUCT_THUMBNAILS":
            const receiveProductThumbnailsAction = incomingAction as ReceiveProductThumbnailsAction;

            const allProductThumbnails = receiveProductThumbnailsAction.productThumbnails;

            filteredProductThumbnails = applyActiveFilters(allProductThumbnails, state.activeFilters);

            [visibleProductsStart, visibleProductsEnd, productsSpacingTop, productsSpacingBottom] = getCurrentlyVisibleProducts(filteredProductThumbnails.length);

            return {
                isLoading: false,
                sortContext: state.sortContext,
                activeFilters: state.activeFilters,
                filterMenus: getFiltersForThumbnails(receiveProductThumbnailsAction.productThumbnails),
                allProductThumbnails: allProductThumbnails,
                filteredProductThumbnails: filteredProductThumbnails,
                visibleProductsStart: visibleProductsStart,
                visibleProductsEnd: visibleProductsEnd,
                productsSpacingTop: productsSpacingTop,
                productsSpacingBottom: productsSpacingBottom
            };
        case "SET_ACTIVE_FILTERS":
            const activeFiltersToSet = (incomingAction as SetActiveFiltersAction).activeFilters;

            filteredProductThumbnails = applyActiveFilters(state.allProductThumbnails, activeFiltersToSet);

            [visibleProductsStart, visibleProductsEnd, productsSpacingTop, productsSpacingBottom] = getCurrentlyVisibleProducts(filteredProductThumbnails.length);

            return {
                isLoading: state.isLoading,
                sortContext: state.sortContext,
                activeFilters: activeFiltersToSet,
                filterMenus: state.filterMenus,
                allProductThumbnails: state.allProductThumbnails,
                filteredProductThumbnails: filteredProductThumbnails,
                visibleProductsStart: visibleProductsStart,
                visibleProductsEnd: visibleProductsEnd,
                productsSpacingTop: productsSpacingTop,
                productsSpacingBottom: productsSpacingBottom
            };
        case "SET_SORT":
            const newSortContext: SortContext = {
                filterId: (incomingAction as SetSortAction).filterId,
                sortDirection: (incomingAction as SetSortAction).sortDirection
            };

            sortThumbnails(state.filteredProductThumbnails, newSortContext);

            [visibleProductsStart, visibleProductsEnd, productsSpacingTop, productsSpacingBottom] = getCurrentlyVisibleProducts(state.filteredProductThumbnails.length);

            return {
                isLoading: state.isLoading,
                sortContext: newSortContext,
                activeFilters: state.activeFilters,
                filterMenus: state.filterMenus,
                allProductThumbnails: state.allProductThumbnails,
                filteredProductThumbnails: state.filteredProductThumbnails,
                visibleProductsStart: visibleProductsStart,
                visibleProductsEnd: visibleProductsEnd,
                productsSpacingTop: productsSpacingTop,
                productsSpacingBottom: productsSpacingBottom
            };
        case "ACTIVATE_FILTER":
            const newActiveFilterId = (incomingAction as ActivateFilterAction).filterId;
            const newActiveFilterValue = (incomingAction as ActivateFilterAction).filterValue;

            newActiveFilters = state.activeFilters.some(f => (f.filterId === newActiveFilterId) && (f.value === newActiveFilterValue))
                ? state.activeFilters
                : state.activeFilters.concat({
                    filterId: newActiveFilterId,
                    value: newActiveFilterValue
                });

            filteredProductThumbnails = applyActiveFilters(state.allProductThumbnails, newActiveFilters);

            [visibleProductsStart, visibleProductsEnd, productsSpacingTop, productsSpacingBottom] = getCurrentlyVisibleProducts(filteredProductThumbnails.length);

            return {
                isLoading: state.isLoading,
                sortContext: state.sortContext,
                activeFilters: newActiveFilters,
                filterMenus: state.filterMenus,
                allProductThumbnails: state.allProductThumbnails,
                filteredProductThumbnails: filteredProductThumbnails,
                visibleProductsStart: visibleProductsStart,
                visibleProductsEnd: visibleProductsEnd,
                productsSpacingTop: productsSpacingTop,
                productsSpacingBottom: productsSpacingBottom
            };
        case "DEACTIVATE_FILTER":
            const filterIdToRemove = (incomingAction as DeactivateFilterAction).filterId;
            const filterValueToRemove = (incomingAction as DeactivateFilterAction).filterValue;

            newActiveFilters = state.activeFilters.filter(f => (f.filterId !== filterIdToRemove) || (f.value !== filterValueToRemove));

            filteredProductThumbnails = applyActiveFilters(state.allProductThumbnails, newActiveFilters);

            [visibleProductsStart, visibleProductsEnd, productsSpacingTop, productsSpacingBottom] = getCurrentlyVisibleProducts(filteredProductThumbnails.length);

            return {
                isLoading: state.isLoading,
                sortContext: state.sortContext,
                activeFilters: newActiveFilters,
                filterMenus: state.filterMenus,
                allProductThumbnails: state.allProductThumbnails,
                filteredProductThumbnails: filteredProductThumbnails,
                visibleProductsStart: visibleProductsStart,
                visibleProductsEnd: visibleProductsEnd,
                productsSpacingTop: productsSpacingTop,
                productsSpacingBottom: productsSpacingBottom
            };
        case "UPDATE_VISIBLE_PRODUCTS":
            [visibleProductsStart, visibleProductsEnd, productsSpacingTop, productsSpacingBottom] = getCurrentlyVisibleProducts(state.filteredProductThumbnails.length);

            return {
                isLoading: state.isLoading,
                sortContext: state.sortContext,
                activeFilters: state.activeFilters,
                filterMenus: state.filterMenus,
                allProductThumbnails: state.allProductThumbnails,
                filteredProductThumbnails: state.filteredProductThumbnails,
                visibleProductsStart: visibleProductsStart,
                visibleProductsEnd: visibleProductsEnd,
                productsSpacingTop: productsSpacingTop,
                productsSpacingBottom: productsSpacingBottom
            };
    }

    return state;
};
